<template>
  <div class="m-3">
    <div class="card-body card-modal pt-0">
      <div class="row justify-content-center">
        <div class="col-12">
          <h4>{{ item.name }}</h4>
          <div class="form-state-deonic">
            <b-form-group
              label="Mostrar"
              v-if="item.type_api == 1"
              description="Seleccione No para ignorar este esado"
            >
              <b-form-radio-group
                v-model="item.options.show"
                :options="yesNo"
              ></b-form-radio-group>
            </b-form-group>

            <b-form-group label="Titulo">
              <b-form-input type="text" v-model="item.title"></b-form-input>
            </b-form-group>

            <b-form-group label="Sub Titulo">
              <b-form-input type="text" v-model="item.subtitle"></b-form-input>
            </b-form-group>

            <div v-if="item.type_api == 1">
              <b-form-group label="Usar en Punto">
                <b-form-radio-group
                  v-model="item.options.showPoint"
                  :options="yesNo"
                ></b-form-radio-group>
              </b-form-group>

              <b-form-group label="Titulo Punto" v-if="item.options.showPoint">
                <b-form-input
                  type="text"
                  v-model="item.options.title_point"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Sub Titulo Punto" v-if="item.options.showPoint">
                <b-form-input
                  type="text"
                  v-model="item.options.subtitle_point"
                ></b-form-input>
              </b-form-group>
            </div>

            <div v-if="item.type_api == 2">
              <ul class="list-group">
                <li
                  class="list-group-item"
                  v-for="(value, index) in item.options"
                  :key="index"
                >
                  <CollapseComponent :title="value.type">

                    <b-form-group label="Titulo">
                      <b-form-input
                        type="text"
                        v-model="value.titles.title"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Sub Titulo">
                      <b-form-input
                        type="text"
                        v-model="value.titles.subtitle"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group label="Mostrar en Gusado de estados">
                      <b-form-radio-group
                        v-model="value.earthworm"
                        :options="yesNo"
                      ></b-form-radio-group>
                    </b-form-group>

                    <b-form-group label="Enviar email al Merchant">
                      <b-form-radio-group
                        v-model="value.merchant_mail"
                        :options="yesNo"
                      ></b-form-radio-group>
                    </b-form-group>

                    <b-form-group label="Enviar email al cliente final">
                      <b-form-radio-group
                        v-model="value.recipient_mail"
                        :options="yesNo"
                      ></b-form-radio-group>
                    </b-form-group>

                    <div v-if="value.merchant_mail">
                      <h4>Mail Merchant</h4>
                      <b-form-group label="Subject">
                        <b-form-input
                          type="text"
                          v-model="value.mail.merchant.subject"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group label="Greeting">
                        <b-form-input
                          type="text"
                          v-model="value.mail.merchant.greeting"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group label="Lines">
                        <b-form-input
                          type="text"
                          v-model="value.mail.merchant.lines"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group label="Actions">
                        <b-form-input
                          type="text"
                          v-model="value.mail.merchant.actions"
                        ></b-form-input>
                      </b-form-group>
                    </div>

                    <div v-if="value.recipient_mail">
                      <h4>Mail al Destinatario</h4>
                      <b-form-group label="Subject">
                        <b-form-input
                          type="text"
                          v-model="value.mail.recipient.subject"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group label="Greeting">
                        <b-form-input
                          type="text"
                          v-model="value.mail.recipient.greeting"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group label="Lines">
                        <b-form-input
                          type="text"
                          v-model="value.mail.recipient.lines"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group label="Actions">
                        <b-form-input
                          type="text"
                          v-model="value.mail.recipient.actions"
                        ></b-form-input>
                      </b-form-group>
                    </div>

                  </CollapseComponent>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class>
      <div class="row">
        <div class="col-12">
          <b-button size="sm" class="mr-1 mt-1 float-right" variant="primary" @click="close"
            >Cerrar</b-button
          >
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import CollapseComponent from "@/layouts/components/CollapseComponent";
import { BButton, BFormGroup, BFormInput, BFormRadioGroup } from 'bootstrap-vue'

export default {
  name: "StateForm",
  components: {
    CollapseComponent,
    BButton,
    BFormGroup,
    BFormInput,
    BFormRadioGroup
  },
  props: {
    item: Object,
  },
  data() {
    return {
      yesNo: [
        { text: "Si", value: true },
        { text: "No", value: false },
      ],
    };
  },

  methods: {
    /**
     *
     */
    close() {
      this.$emit("close");
      // this.onClose.handler();
    },
  },
};
</script>
<style lang="scss">
.card-modal {
  max-height: 70%;
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

span {
  font-weight: 500;
}

.left {
  display: table-cell;
  button {
    margin-top: 10px;
    margin-right: 10px;
  }
}

.right {
  display: table-cell;
}
</style>
