<template>
  <b-card ref="container">
    <b-form @submit="onSubmit">
      <div class="px-0">
        <b-card-title class="card-title m-0">
          <b-row class="row mx-0">
            <b-col class="mt-1">
              <h1>Unigis</h1>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-end my-2">
              <b-button class="btn btn-sm" type="submit">Guardar</b-button>
            </b-col>
          </b-row>
        </b-card-title>
        <div class="px-0">
          <b-col class="px-0">
            <div class="px-2">
              <p>
                Si quieres mostrar Información de la operación recuerda utilizar
                los siguientes atajos
              </p>
              <ul>
                <li>
                  <p><strong> [see]</strong> Mostrar link de detalle.</p>
                </li>
                <li>
                  <p>
                    <strong>[tracking] </strong>
                    Mostrar Tracking de a operación.
                  </p>
                </li>
                <li>
                  <p><strong>[brand] </strong> Mostrar Nombre de la Tienda.</p>
                </li>
                <li>
                  <p><strong>[name] </strong> Mostrar Nombre del cliente.</p>
                </li>
                <li>
                  <p>
                    <strong>[fullAddress] </strong>
                    Mostrar dirección de envio.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>[fullAddressCollection] </strong>
                    Mostrar dirección de la de colecta.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>[merchantAddress]</strong>
                    Mostrar dirección de la tienda.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>[linkLabelTracking]</strong>
                    Mostrar Link a etiqueta.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>[codigoSeguimientoOCASA]</strong>
                    Mostrar codigo seguimiento OCASA.
                  </p>
                </li>
              </ul>
            </div>
            <div class="table-responsive-lg">
              <table
                class="table table-sm table-striped table-bordered align-middle"
              >
                <thead>
                  <tr class="tr-state">
                    <th scope="col">Estado</th>
                    <th scope="col">Titulo</th>
                    <th scope="col">Sub Titulo</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="tr-state"
                    v-for="item in unigis"
                    :key="item.id"
                    :item="item"
                  >
                    <th scope="row" class="align-middle">{{ item.name }}</th>
                    <td class="align-middle">{{ item.title }}</td>
                    <td class="align-middle">{{ item.subtitle }}</td>
                    <td class="text-center">
                      <feather-icon
                        @click="onEdit(item)"
                        size="20"
                        icon="Edit3Icon"
                        class="mr-50 editIcon"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </div>
      </div>
    </b-form>
  </b-card>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UserService from "@/services/UserService";
import SettingService from "@/services/SettingService";
import StateForm from "./StateForm";
import { BForm, BRow, BCol, BCard, BCardTitle, BButton } from "bootstrap-vue";

export default {
  name: "StateOperation",
  props: {
    deonics: Array,
    unigis: Array,
  },
  components: {
    BForm,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BButton,
  },
  data() {
    return {
      fields: [
        {
          name: "name",
          title: "Estado",
        },
        {
          name: "title",
          title: "Titulo",
          titleClass: "center aligned",
          dataClass: "center aligned",
        },
        {
          name: "subtitle",
          title: "Sub Titulo",
        },
        {
          name: "buttons-slot",
          title: "ACCIONES",
        },
      ],
    };
  },
  methods: {
    /**
     *
     */
    onSubmit(event) {
      event.preventDefault();
      SettingService.saveStates(this.unigis)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Configuración`,
              text: `Configuración Actualizada`,
              icon: "InfoIcon",
              variant: "info",
            },
          });
        })
        .catch((error) => {
          console.error(error);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Configuración`,
              text: `Error al tratar de almacenar este elemento`,
              icon: "AlertOctagonIcon",
              variant: "danger",
            },
          });
        });
    },

    /**
     *
     */
    onEdit(item) {
      // validar que tenga todo los tipos de envios
      const elements = [
        "Colecta/Entrega",
        "Colecta/Pick Up",
        "Drop Off/Entrega",
        "Retiro/Devolucion",
        "Drop Off/Pick Up",
        "Devolucion en Punto/Devolucion",
        "Cambio desde Colecta",
        "Cambio desde Drop Off",
      ];

      const addTypes = elements.filter(
        (d) => item.options.map((f) => f.type).indexOf(d) < 0
      );

      addTypes.forEach((e) =>
        item.options.push({
          type: e,
          earthworm: false,
          merchant_mail: false,
          recipient_mail: false,
          titles: {
            title: null,
            subtitle: null,
          },
          mail: {
            merchant: {
              greeting: "Hola! [brand]",
              actions: "[see]",
            },
            recipient: {
              greeting: "Hola! [brand]",
              actions: "[see]",
            },
          },
        })
      );
      console.log(item);
      this.$modal.show(
        StateForm,
        {
          item,
          onClose: {
            handler: () => {},
          },
          onSave: {
            handler: () => {
              this.$refs.fulltable.refresh();
            },
          },
        },
        {
          styles:
            "overflow-x: hidden; overflow-y: scroll; max-height: 600px; border-radius: 10px; margin-left: 10%",
          adaptive: true,
          width: "70%",
          height: "auto",
        }
      );
    },
  },
  async mounted() {
    if (!UserService.isAdmin()) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped>
.thead-dar-change {
  background-color: #fafafc !important;
}

.editIcon {
  color: #f68d1e !important;
  cursor: pointer;
}

.card-body {
  padding: 0 !important;
}

.tr-state {
  text-align: left !important;
  th {
    padding-left: 15px !important;
  }
}
</style>
