import axios from 'axios';

export default class SettingService {

    /**
     * 
     */
    static statuses(type) {
        const params = {
            type
        };
        return axios.get(process.env.VUE_APP_API + '/settings/statuses', {
            params
        });
    }

    /**
     * 
     */
    static postalCode() {
        return axios.get(process.env.VUE_APP_API + '/settings/postal-code');
    }

    /**
     * 
     */
    static setPostalCode(postalCode) {
        return axios.post(process.env.VUE_APP_API + '/settings/postal-code', postalCode);
    }

    /**
     * 
     */
    static getPostalCodeValid() {
        return axios.get(process.env.VUE_APP_API + '/settings/postal-code-valid');
    }

    /**
     * 
     */
    static setPostalCodeValid(data) {
        return axios.post(process.env.VUE_APP_API + '/settings/postal-code-valid', data);
    }

    /**
     * 
     */
    static declaredPercentage() {
        return axios.get(process.env.VUE_APP_API + '/settings/declared-percentage');
    }

    /**
     * 
     */
    static setDeclaredPercentage(value) {
        return axios.post(process.env.VUE_APP_API + '/settings/declared-percentage', value);
    }

    /**
     * 
     */
    static worm() {
        return axios.get(process.env.VUE_APP_API + '/settings/worm');
    }

    /**
     * 
     */
    static saveWorm(data) {
        return axios.post(process.env.VUE_APP_API + '/settings/worm', data);
    }

    /**
     * 
     * @param {*} data 
     */
    static saveStates(data) {
        return axios.post(process.env.VUE_APP_API + '/settings/statuses', data);
    }

}