<template>
  <div>
    <StateOperation :deonics="deonics" :unigis="unigis" />
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import StateOperation from "./StateOperation";
import SettingService from "@/services/SettingService";

export default {
  name: "Setting",
  components: {
    StateOperation,
  },
  data() {
    return {
      deonics: [],
      unigis: [],
    };
  },
  methods: {},
  async mounted() {
    try {
      this.deonics = (await SettingService.statuses(1)).data.data;
      this.unigis = (await SettingService.statuses(2)).data.data;
      this.unigis = this.unigis.map((f) => {
        if (f.options.length == 0) {
          f.options = [
            {
              type: "Colecta/Entrega",
              earthworm: false,
              merchant_mail: false,
              recipient_mail: false,
              titles: {
                title: "",
                subtitle: "",
              },
              mail: {
                merchant: {
                  lines: "",
                  subject: "",
                  greeting: "Hola! [name]",
                  actions: "[see]",
                },
                recipient: {
                  lines: "",
                  subject: "",
                  greeting: "Hola! [name]",
                  actions: "[see]",
                },
              },
            },
            {
              type: "Drop Off/Entrega",
              earthworm: false,
              merchant_mail: false,
              recipient_mail: false,
              titles: {
                title: "",
                subtitle: "",
              },
              mail: {
                merchant: {
                  lines: "",
                  subject: "",
                  greeting: "Hola! [name]",
                  actions: "[see]",
                },
                recipient: {
                  greeting: "Hola! [brand]",
                  actions: "[see]",
                  lines: "",
                  subject: "",
                },
              },
            },
            {
              type: "Retiro/Devolucion",
              earthworm: false,
              merchant_mail: false,
              recipient_mail: false,
              titles: {
                title: "",
                subtitle: "",
              },
              mail: {
                merchant: {
                  lines: "",
                  subject: "",
                  greeting: "Hola! [name]",
                  actions: "[see]",
                },
                recipient: {
                  lines: "",
                  subject: "",
                  greeting: "Hola! [name]",
                  actions: "[see]",
                },
              },
            },
            {
              type: "Devolucion en Punto/Devolucion",
              earthworm: false,
              merchant_mail: false,
              recipient_mail: false,
              titles: {
                title: "",
                subtitle: "",
              },
              mail: {
                merchant: {
                  lines: "",
                  subject: "",
                  greeting: "Hola! [name]",
                  actions: "[see]",
                },
                recipient: {
                  lines: "",
                  subject: "",
                  greeting: "Hola! [name]",
                  actions: "[see]",
                },
              },
            },
          ];
        }
        return f;
      });
    } catch (error) {
      console.error(error);
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: `Configuración`,
          text: `Error al tratar de cargar información`,
          icon: "AlertOctagonIcon",
          variant: "danger",
        },
      });
    }
  },
};
</script>